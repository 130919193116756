import axios from "axios";
const HOST = "https://www.scon.app:5000";
//const HOST = "http://localhost:5000";
const HEADER = {
    "api-version": "0.1",
    "app-version": "0.0.1",
    "app-locale": "kr_KO",
    'Content-Type': 'multipart/form-data'
}

export const requestMail = (sendMail:string,title:string,message:string) => {
    var formData = new FormData();
    formData.append("sendMail", sendMail);
    formData.append("title", title);
    formData.append("content", message);
    return new Promise((resolve,reject)=>{
        axios.post(HOST+"/si/mail",formData,{headers:HEADER})
        .then(res=>{
            resolve(res.data)
        })
        .catch(err=>{
            console.error(err);
            reject(err)
        })
    })
}