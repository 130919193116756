import React, { Component } from 'react';
import DaumMap from './DaumMap';

class index extends Component {
    geocoder = null;
    map = null;
    markers = [];
    infos = [];
    customRender = (element, daum) => {


        const lat = 37.503806, lng = 126.730867
        const company_lat = 37.502139, company_lng = 126.730569;

        daum.maps.load(() => {

            this.map = new daum.maps.Map(element, {
                center: new daum.maps.LatLng(lat, lng),
                level: 4
            })

            // const marker = new daum.maps.Marker({
            //     position: new daum.maps.LatLng(lat, lng),
            // })
            let iwContent = '<div style="padding:5px;">"인천 부평구 장제로 187 3층 (주)스콘"</div>'
            let iwPosition  = new daum.maps.LatLng(lat, lng)
            let infowindow = new daum.maps.InfoWindow({
                map: this.map, // 인포윈도우가 표시될 지도
                position : iwPosition, 
                content : iwContent,
            });

            this.geocoder = new daum.maps.services.Geocoder();        
            //marker.setMap(this.map)

        })

    }

    render() {
        return (
            <div style={{ width: '100%', height: '500px' }}>
                <DaumMap
                    apiKey='3dc5f9de8ffe11f1cfea3958e69b3b6e'
                    mapId={'daum-map'}
                    render={this.customRender}
                />
            </div>
        )
    }
}

export default index;