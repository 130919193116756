import React from 'react';
import logo from './logo.svg';
import Lottie from 'react-lottie';
import './App.css';
import DaumMap from './components/DaumMap';
import Carousel from 'nuka-carousel';
import Cover1 from './static/cover/reviewbanner01_1200x360.jpg';
import Cover2 from './static/cover/reviewbanner02_1200x360.jpg';
import scrollIntoView from 'scroll-into-view-if-needed';
import { requestMail } from './services/AxiosService';

const App: React.FC = () => {

  function moveSection(id: string) {
    const node = document.getElementById(id) as HTMLElement
    console.log(node);

    scrollIntoView(node, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
      block: "start",
      inline: "start"
    });

  }

  function siMailRequest(e: any) {
    e.preventDefault();
    let sendMail = (document.getElementsByName("sendMail")[0] as HTMLInputElement).value;
    let title = (document.getElementsByName("title")[0] as HTMLInputElement).value;
    let content = (document.getElementsByName("content")[0] as HTMLInputElement).value;
    //console.log(sendMail,title,content);
    requestMail(sendMail,title,content)
    .then(result=>{
      alert("문의되었습니다.\n검토 후 연락 드리겠습니다\n감사합니다.")
    })
    .catch(err=>{
      alert("문제가 발생하였습니다.\n잠시후 다시 시도해주세요.")
    })
    
  }

  return (
    <React.Fragment>
      <div className="header">
        <div className="logo_tl">
          <Lottie options={{
            loop: true,
            autoplay: true,
            animationData: require('./static/json/logo.json'),
          }} />
        </div>
        <div className="menu_tl">
          <ul>
            <li onClick={() => { moveSection("section-1") }}>회사소개</li>
            <li onClick={() => { moveSection("section-2") }}>구성원</li>
            {/* <li>프로젝트 내역</li> */}
            <li onClick={() => { moveSection("section-3") }}>오시는길</li>
          </ul>
        </div>
      </div>
      <div className="about_1">
        <Carousel
          wrapAround={true}
          autoplay={true}
          renderCenterLeftControls={() => (
            null
          )}
          renderCenterRightControls={() => (
            null
          )}
          renderBottomCenterControls={() => (
            null
          )}
        >
          <img src={Cover1} />
          <img src={Cover2} />
        </Carousel>
        <div className="main_copy">
          동반성장을 원하시면<br />
          저희 스콘에게 연락주세요.
        </div>
        <div className="copy_right">COPYRIGHT© SCON. ALL RIGHTS RESERVED</div>
      </div>
      <div id={"section-1"} className="about_tl about_2">
        <div className="section_title">전문 서비스 분야</div>
        <div className="section_contents">
          <ul>
            <li>
              <div className="main_circle"><div className="circle_title">플랫폼<br />
                개발</div></div>
              <div className="main_desc">시장검증을 위한 프로토 타입개발, 서비스 개발
                    및 유지보수가 가능합니다.</div>
            </li>
            <li>
              <div className="main_circle"><div className="circle_title">어플리케이션<br />
                개발</div></div>
              <div className="main_desc">다중 플랫폼으로 서비스가 가능한 Restfull API
                    기반 어플리케이션을 개발해 드립니다.</div>
            </li>
            <li>
              <div className="main_circle"><div className="circle_title">소프트웨어<br />
                개발</div></div>
              <div className="main_desc">운영체제 환경에서 사용되는 소프트웨어
                    개발이 가능합니다.</div>
            </li>
          </ul>

        </div>

      </div>
      <div className="about_tl about_3">
        <div className="section_title">스콘 비전</div>
        <div className="section_contents">
          <div className="main_title">기술적으로 이룰 수 있는 시스템 개선의 동반자</div>
          <div className="main_desc">우리는 문제를 해결하는 것에 관심이 있으며<br />
            이러한 문제를 해결하기 위한 팀들과 함께 성장해 가는 회사입니다.<br />
            구체적인 개선방안이 확실하지 않더라도, 저희가 같이 파악하고 고민해드릴 수 있는 회사입니다.
          </div>

        </div>
      </div>
      <div className="about_tl about_4">
        <div className="section_title">스콘의 일하는 방식</div>
        <div className="section_contents">
          <ul>
            <li>
              <div className="number_title">1</div>
              <div className="main_title">개발자미팅</div>
              <div className="main_desc">프로젝트 개발자가 직접 고객과 회의를하며 고객의 정확한 요구사항을 파악하고, 고객에게 정확한 피드백을 드립니다.
                </div>
            </li>
            <li>
              <div className="number_title">2</div>
              <div className="main_title">의문제기</div>
              <div className="main_desc">퀄리티를 높이기 위해, 스스로에게 늘 의문을 던지며 가장 최선의 방법과 결과물을 만들기 위해 노력합니다.
                </div>
            </li>
            <li>
              <div className="number_title">3</div>
              <div className="main_title">개선제안</div>
              <div className="main_desc">프로젝트에 새로운 개선이 필요하다고 생각되면 타당한 근거와 개선방향을 정리하여 제안합니다.
                </div>
            </li>
          </ul>

        </div>
      </div>
      <div className="about_tl about_5">
        <div className="section_title">스콘의 특징</div>
        <div className="section_contents">
          <ul>
            <li>
              <div className="main_circle">활동적</div>
              <div className="main_desc">책상에만 있지않고 여러사람과 여러상황에 대해서 배우기위해 늘 움직입니다.</div>
            </li>
            <li>
              <div className="main_circle">유연함</div>
              <div className="main_desc">요구사항변동에 대해서 최대한 대처가 가능하도록 유연한 작업을 합니다.</div>
            </li>
            <li>
              <div className="main_circle">대화</div>
              <div className="main_desc">활발한 의사소통이 프로젝트 성공의 원동력이라고 생각합니다.</div>
            </li>
            <li>
              <div className="main_circle">동반성장</div>
              <div className="main_desc">회사와 회사간의 협력을 통한 동반성장을 추구합니다.</div>
            </li>
          </ul>

        </div>
      </div>
      <div id={"section-2"} className="about_tl about_6">
        <div className="section_title">스콘 구성원</div>
        <div className="section_contents">
          <ul>
            <li>
              <div className="main_img">
                <div className="img_hover">
                  <div className="profile_title">기준수</div>
                  <div className="profile_message">하고싶은 말을 적는 곳입니다. 이정도 길을 적었으면 좋을 것같습니다. 책상에만 있지않고 여러사람과 여러상황에 대해서 배우기위해 늘 움직입니다.</div>
                </div>
                <img src="./img/profile_img1@2x.png" />
              </div>
              <div className="profile_name">경영기획실/ 실장</div>
              <div className="profile_email">scon@scon.app</div>
            </li>
            <li>
              <div className="main_img">
                <div className="img_hover">
                  <div className="profile_title">신유동</div>
                  <div className="profile_message">하고싶은 말을 적는 곳입니다. 이정도 길을 적었으면 좋을 것같습니다. 책상에만 있지않고 여러사람과 여러상황에 대해서 배우기위해 늘 움직입니다.</div>
                </div>
                <img src="./img/profile_img2@2x.png" />
              </div>
              <div className="profile_name">개발실/ 실장</div>
              <div className="profile_email">ud4mo@scon.app</div>
            </li>
          </ul>

        </div>
      </div>
      <div id={"section-3"} className="about_tl about_7">
        <div className="section_title">컨텍트</div>
        <div className="section_contents">
          <DaumMap />
          <form onSubmit={siMailRequest}>
            <div className="form_tl">
              <input name={"sendMail"} type="text" placeholder="이메일" required={true}/>
              <input name={"title"} type="text" placeholder="제목" required={true}/>
              <textarea name={"content"} placeholder="문의내용" required={true}></textarea>
              <button style={{"border":"0"}} type={"submit"} className="submit_bt">문의하기</button>
            </div>
          </form>
        </div>
      </div>
      <div className="footer">
        <div className="left_section">
          <div className="ft_title">사업자정보</div>
          <div className="ft_contents">
            인천시 부평구 장제로 187, 3층<br />
            대표자  기준수 사업자등록번호  243-87-01155<br />
            COPYRIGHT© SCON. ALL RIGHTS RESERVED<br />
          </div>
        </div>
        <div className="right_section">
          {/* <div className="ft_title">1:1 문의</div> */}
          <div className="ft_contents">
            {/* SNS ID 를 알려주세요<br /> */}
            운영시간 / 평일 09:00~18:00<br />
            / 점심 11:30~13:00<br />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
